import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClientProvider, hydrate, dehydrate } from 'react-query'

import queryClient from 'utils/queryClient';
import routes from './routes/routes';

function App() {
  useEffect(() => {
    const cachedData = localStorage.getItem('queryCache')
    if (cachedData) {
      const parsedData = JSON.parse(cachedData)
      hydrate(queryClient, parsedData)
    }
  }, [])

  // 캐시 상태가 변경될 때마다 로컬 스토리지를 업데이트
  useEffect(() => {
    const cacheInterval = setInterval(() => {
      localStorage.setItem('queryCache', JSON.stringify(dehydrate(queryClient)))
    }, 1000)

    return () => clearInterval(cacheInterval)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Suspense>
          <Routes>
            {routes.map(({ path, component: Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
