import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkLogin, useSetupPageHeight } from '../utils/common';

import CommonAlert from 'components/CommonAlert';
import Footer from 'components/Footer';
import Header from 'components/Header';

import mainBanner from 'assets/images/home/mainBanner.png';
import lp1 from 'assets/images/home/lpGroup/1.svg';
import lp2 from 'assets/images/home/lpGroup/2.svg';
import lp3 from 'assets/images/home/lpGroup/3.svg';
import lp4 from 'assets/images/home/lpGroup/4.svg';
import lp5 from 'assets/images/home/lpGroup/5.svg';
import lp6 from 'assets/images/home/lpGroup/6.svg';

import 'scss/app/Home.scss';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);

    useSetupPageHeight({ includeHeader: true, includeFooter: true });

    const alertData = {
        title: '인증 필요',
        text: '본인 인증 후 이용 가능한 서비스 입니다.',
        button: '확인',
        route: '/api/identify-verification',
        close: '/',
    };

    const 홈보험리스트 = [
        { label: '관광공제회', disc: '화재, 배상사고<br>피해보상', route: '/claim/tourism-deduction', Images: lp1 },
        { label: '유도선 사업자<br>배상책임보험', disc: '유도선 사고 승객<br>피해보상', route: '/claim/tugboat-operator-liability-insurance', Images: lp3 },
        { label: '주차장 배상<br>책임보험', disc: '주차장 내 사고<br>피해보상', route: '/claim/parking-lot-liability-insurance', Images: lp2 },
        { label: '연장보증보험', disc: '제품 보증 기간<br>연장 보험', route: '/claim/extended-warranty-insurance', Images: lp4 },
        { label: '단체상해보험', disc: '질병, 상해사고<br>피해보상', route: '/claim/group-accident-insurance/0', Images: lp5 },
        { label: '기타보험', disc: '', route: '#', Images: lp6 },
    ];

    useEffect(() => {
        const storageKeys = [
            'tourismDeductionFormData',
            'extendedInsuranceFormData',
            'groupInsuranceFormData',
            'parkingInsuranceFormData',
            'tugboatInsuranceFormData'
        ];

        // 각 보험에서 입력된 사용자 데이터 일괄 초기화
        storageKeys.forEach(key => localStorage.removeItem(key));
    }, []);

    const renderOtherScreen = (item: any) => {
        if (item.label === '단체상해보험') {
            navigate(item.route);
        } else {
            if (!checkLogin()) {
                setShowAlert(true);
            } else {
                navigate(item.route);
            }
        }
    };

    return (
        <div>
            <CommonAlert
                AlertData={alertData}
                showAlert={showAlert}
                onClose={() => setShowAlert(false)}
            />
            <Header />
            <section id="홈">
                <div style={{ backgroundColor: 'var(--background)' }}>
                    <img src={mainBanner} alt="" />

                    <h5 className="mt24 mb16">보험금 청구</h5>
                    <article className="lpGroup">
                        {홈보험리스트.map((item, i) => (
                            <a className="item" onClick={() => renderOtherScreen(item)} key={i}>
                                <div className="title">
                                    <p className="fw600 mb4" dangerouslySetInnerHTML={{ __html: item.label }}></p>
                                    <h6 dangerouslySetInnerHTML={{ __html: item.disc }}></h6>
                                </div>
                                <img className="roundsm" src={item.Images} alt={item.label} />
                            </a>
                        ))}
                    </article>

                    <article className="info item" onClick={() => navigate('/qna/require')}>
                        <div>
                            <i className="icon icon-required-check-o icon-20"></i>
                            <p className="fw600">필수서류 안내</p>
                        </div>
                        <i className="icon icon-arrow-right bg-gray icon-12"></i>
                    </article>

                    <article className="info item" onClick={() => navigate('/qna')}>
                        <div>
                            <i className="icon icon-info-o icon-20"></i>
                            <p className="fw600">자주 묻는 질문</p>
                        </div>
                        <i className="icon icon-arrow-right bg-gray icon-12"></i>
                    </article>
                </div>
            </section>
            <Footer currentPage="home" />
        </div>
    );
};

export default Home;
