import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionSheet from './ActionSheet';
import styles from './css/Footer.module.css';

interface FooterProps {
    currentPage: string;
}

const Footer: React.FC<FooterProps> = ({ currentPage }) => {
    const navigate = useNavigate();
    const [showActionSheet, setShowActionSheet] = useState(false);

    const sheetData = [
        {
            title: '내역보기',
            items: [
                { itemTitle: '관광공제회', route: '/history' },
                { itemTitle: '유도선 사업자 배상책임보험', route: '/history' },
                { itemTitle: '주차장 배상책임보험', route: '/history' },
                { itemTitle: '연장보증보험', route: '/history' },
                { itemTitle: '단체상해보험', route: '/history' },
                { itemTitle: '기타보험', route: '/history' },
            ],
        },
    ];

    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.div}>
                    <i
                        className={`${styles.icon} icon icon-footer-home icon-36 ${currentPage === 'home' ? 'bg-blue' : 'bg-lightGray'}`}
                        onClick={() => navigate('/')}
                    ></i>
                    <i
                        className={`${styles.icon} icon icon-footer-history icon-36 ${currentPage === 'history' ? 'bg-blue' : 'bg-lightGray'}`}
                        onClick={() => setShowActionSheet(true)}
                    ></i>
                    <i
                        className={`${styles.icon} icon icon-footer-setting icon-36 ${currentPage === 'setting' ? 'bg-blue' : 'bg-lightGray'}`}
                        onClick={() => navigate('/accounts/setting')}
                    ></i>
                </div>
            </footer>
            {showActionSheet && (
                <ActionSheet
                    sheetData={sheetData}
                    showActionSheet={showActionSheet}
                    onClose={() => setShowActionSheet(false)}
                />
            )}
        </>
    );
};

export default Footer;
