import { useEffect } from "react";

interface PageHeightOptions {
  includeHeader?: boolean;
  includeFooter?: boolean;
  includeSafeArea?: boolean;
}

export function setPageHeight({
  includeHeader = true,
  includeFooter = true,
  includeSafeArea = true,
}: PageHeightOptions = {}) {
  const vh = window.innerHeight * 0.01;
  const headerHeight = includeHeader ? 62 : 0; // 헤더 높이
  const footerHeight = includeFooter ? 75 : 0; // 푸터 높이
  const safeArea = includeSafeArea ? 52 : 0; // 세이프티 에리아 높이
  const divs = document.querySelectorAll<HTMLElement>(
    "section > div:first-child"
  );

  if (divs.length > 0) {
    divs.forEach((div) => {
      div.style.height = `${
        vh * 100 - headerHeight - footerHeight - safeArea
      }px`;
      div.style.maxHeight = `${
        vh * 100 - headerHeight - footerHeight - safeArea
      }px`;
    });
  }
}

export function useSetupPageHeight(options: PageHeightOptions) {
  useEffect(() => {
    const updateHeight = () => setPageHeight(options);

    // 초기 설정
    setPageHeight(options);
    window.addEventListener("resize", updateHeight);

    // cleanup 함수
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [options]);
}

export function checkLogin(): boolean {
  const token = localStorage.getItem("accessToken");
  return token ? true : false;
}
