import React from 'react';
import styles from './css/Comment.module.css';

interface CommentProps {
    comments: string[];
}

const Comment: React.FC<CommentProps> = ({ comments }) => {
    return (
        <article className={styles.container}>
            {comments.map((comment, index) => (
                <div className={styles.comment} key={index}>
                    <h6>·</h6>
                    <h6>{comment}</h6>
                </div>
            ))}
        </article>
    );
};

export default Comment;
