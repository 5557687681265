import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Comment from './Comment';
import styles from './css/ActionSheet.module.css';

interface Item {
    itemTitle: string;
    route: string;
}

interface SheetData {
    title: string;
    items: Item[];
    component?: string;
}

interface ActionSheetProps {
    sheetData: SheetData[];
    showActionSheet: boolean;
    onClose: () => void;
}

const ActionSheet: React.FC<ActionSheetProps> = ({ sheetData, showActionSheet, onClose }) => {
    const navigate = useNavigate();
    const comments = [
        "서류를 접힌 부분 없이 깔끔하게 펴서 촬영해 주세요.",
        "촬영 시 카메라 초점을 흐려지지 않게 맞춰 주세요.",
        "그림자가 지지 않도록 배치하여 촬영해 주세요."
    ];

    const openCamera = () => {
        if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('openCamera');
        }
    }

    const openGallery = () => {
        if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('openGallery');
        }
    }

    const openFile = () => {
        if (window && window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('openFile');
        }
    }

    return (
        <>
            {showActionSheet && (
                <>
                    <motion.div
                        className={styles.actionSheet}
                        initial={{ y: 600 }}
                        animate={{ y: 0 }}
                        exit={{ y: 600 }}
                        transition={{ duration: 0.5 }}
                    >
                        {!sheetData[0].component && (
                            <>
                                <div className={styles.header}>
                                    <i className="icon icon-20"></i>
                                    <h5>{sheetData[0].title}</h5>
                                    <i className="icon icon-x icon-20" onClick={onClose}></i>
                                </div>
                                <div className={styles.items}>
                                    {sheetData[0].items.map((item, index) => (
                                        <>
                                            <div key={index}>
                                                <p className="fw600">{item.itemTitle}</p>
                                                <button className={styles.button} onClick={() => {
                                                    navigate(item.route, { state: { selectedOption: item.itemTitle } });
                                                    onClose();
                                                }}>보기</button>
                                            </div>
                                            <div style={{ width: '100%', height: 1, background: 'var(--disabled)' }} className={styles.underline}></div>
                                        </>
                                    ))}
                                </div>
                            </>
                        )}

                        {sheetData[0].component === '증빙서류 첨부' && (
                            <>
                                <div className={styles.header}>
                                    <i className="icon icon-20"></i>
                                    <h5>증빙서류 첨부</h5>
                                    <i className="icon icon-x icon-20" onClick={onClose}></i>
                                </div>
                                <div className={styles.items}>
                                    <Comment comments={comments} />
                                    <div className="mt24">
                                        <div onClick={openCamera}>
                                            <i className="icon icon-camera icon-24 mr6"></i>
                                            <p className="fw600">카메라</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>
                                    <div className={styles.underline}></div>
                                    <div>
                                        <div onClick={openGallery}>
                                            <i className="icon icon-photo icon-24 mr6"></i>
                                            <p className="fw600">앨범</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>
                                    <div className={styles.underline}></div>
                                    <div>
                                        <div onClick={openFile}>
                                            <i className="icon icon-clip icon-24 mr6"></i>
                                            <p className="fw600">내 파일</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>
                                    <div className={styles.underline}></div>
                                </div>
                            </>
                        )}
                    </motion.div>
                    <motion.div
                        className='background'
                        onClick={onClose}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    />
                </>
            )}
        </>
    );
};

export default ActionSheet;
