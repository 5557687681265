import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './css/Alert.module.css';

interface AlertData {
    title: string;
    text: string;
    button: string;
    route?: string;
    close?: string;
}

interface AlertProps {
    AlertData: AlertData;
    showAlert: boolean;
    onClose: () => void;
}

const CommonAlert: React.FC<AlertProps> = ({ AlertData, showAlert, onClose }) => {
    const navigate = useNavigate();

    const closeAlert = () => {
        if (AlertData.close) {
            navigate(AlertData.close);
        } else {
            onClose();
        }
    };

    const handleButtonClick = () => {
        closeAlert();
        if (AlertData.route) {
            navigate(AlertData.route);
        }
    };

    return (
        <>
            {showAlert && (
                <>
                    <motion.div
                        className="background"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        onClick={onClose}
                    />
                    <motion.div
                        className={styles.alert}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* <i className={`${styles.icon} icon icon-x icon-20`} onClick={closeAlert}></i> */}
                        <div>
                            <h5 className="fw700 my12">{AlertData.title}</h5>
                            <span className="dblock mb28" dangerouslySetInnerHTML={{ __html: AlertData.text }}></span>
                        </div>
                        <button className={styles.button} onClick={handleButtonClick}>
                            <h5 className="white fw700">{AlertData.button}</h5>
                        </button>
                    </motion.div>
                    <motion.div
                        onClick={closeAlert}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    />
                </>
            )}
        </>
    );
};

export default CommonAlert;
