import React from 'react';

import Home from '../pages/Home';

// 계정 관련 스크린
const AlarmScreen = React.lazy(() => import('pages/accounts/alarm/AlarmScreen'));
const AccountDelete = React.lazy(() => import('pages/accounts/delete/AccountDeleteScreen'));
const SettingScreen = React.lazy(() => import('pages/accounts/setting/SettingScreen'));

// 문의 스크린
const RequiredDocs = React.lazy(() => import('pages/qna/require/RequiredDocs'));
const RequiredDocsOption = React.lazy(() => import('pages/qna/require/RequiredDocsOption'));
const NewInquiryScreen = React.lazy(() => import('pages/qna/new/NewInquiryScreen'));
const InquiryDetailScreen = React.lazy(() => import('pages/qna/detail/InquiryDetailScreen'));
const FrequentlyAskedQuestions = React.lazy(() => import('pages/qna/FrequentlyAskedQuestions'));
const InquiryHistoryScreen = React.lazy(() => import('pages/qna/inquiry-history/InquiryHistoryScreen'));

// 약관 스크린
const TermsOfService = React.lazy(() => import('pages/terms/TermsOfService'));
const PrivacyOfService = React.lazy(() => import('pages/terms/PrivacyOfService'));
const WebsiteTermsOfUse = React.lazy(() => import('pages/terms/WebsiteTermsOfUse'));
const IdentifiableTerms = React.lazy(() => import('pages/terms/IdentifiableTerms'));
const PersonalCollectionTerms = React.lazy(() => import('pages/terms/PersonalCollectionTerms'));
const ElecttronicFinancialTerms = React.lazy(() => import('pages/terms/ElecttronicFinancialTerms'));

// 히스토리 스크린
const HistoryScreen = React.lazy(() => import('pages/history/HistoryScreen'));
const HistoryDetailScreen = React.lazy(() => import('pages/history/detail/HistoryDetailScreen'));

// 공지사항 스크린
const NoticeScreen = React.lazy(() => import('pages/notice/NoticeScreen'));
const NoticeDetailScreen = React.lazy(() => import('pages/notice/NoticeDetailScreen'));

// 단체상해보험
const GroupAccidentInsurance = React.lazy(() => import('pages/claim/group-accident-insurance/GroupAccidentInsurance'));
const GroupAccidentInsurance0Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step0'));
const GroupAccidentInsurance1Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step1'));
const GroupAccidentInsurance2Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step2'));
const GroupAccidentInsurance3Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step3'));
const GroupAccidentInsurance4Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step4'));
const GroupAccidentInsurance5Step = React.lazy(() => import('pages/claim/group-accident-insurance/Step5'));

// 주차장 배상책임보험
const ParkingLotLiabilityInsurance = React.lazy(() => import('pages/claim/parking-lot-liability-insurance/ParkingLotLiabilityInsurance'));
const ParkingLotLiabilityInsurance1Step = React.lazy(() => import('pages/claim/parking-lot-liability-insurance/Step1'));
const ParkingLotLiabilityInsurance2Step = React.lazy(() => import('pages/claim/parking-lot-liability-insurance/Step2'));

// 연장 보증보험
const ExtendedWarrantyInsurance = React.lazy(() => import('pages/claim/extended-warranty-insurance/ExtendedWarrantyInsurance'));
const ExtendedWarrantyInsurance1Step = React.lazy(() => import('pages/claim/extended-warranty-insurance/Step1'));
const ExtendedWarrantyInsurance2Step = React.lazy(() => import('pages/claim/extended-warranty-insurance/Step2'));

// 유도선 사업자 배상책임보험
const TugboatOperatorLiabilityInsurance = React.lazy(() => import('pages/claim/tugboat-operator-liability-insurance/TugboatOperatorLiabilityInsurance'));
const TugboatOperatorLiabilityInsurance1Step = React.lazy(() => import('pages/claim/tugboat-operator-liability-insurance/Step1'));
const TugboatOperatorLiabilityInsurance2Step = React.lazy(() => import('pages/claim/tugboat-operator-liability-insurance/Step2'));

// 관광공제회
const TourismDeduction = React.lazy(() => import('pages/claim/tourism-deduction/TourismDeduction'));
const TourismDeduction1Step = React.lazy(() => import('pages/claim/tourism-deduction/Step1'));
const TourismDeduction2Step = React.lazy(() => import('pages/claim/tourism-deduction/Step2'));

// 패스인증 콜백
const AuthenticationCallback = React.lazy(() => import('pages/api/auth/CallbackPage'));
const IdentityVerification = React.lazy(() => import('pages/api/auth/IdentityVerification'));

interface Route {
    path: string;
    component: React.ComponentType;
}

const verifyRoutes: Route[] = [
    { path: '/api/auth/*', component: AuthenticationCallback },
    { path: '/api/identify-verification', component: IdentityVerification },
];

const accountRoutes: Route[] = [
    { path: '/accounts/notice', component: AlarmScreen },
    { path: '/accounts/setting', component: SettingScreen },
    { path: '/accounts/delete', component: AccountDelete },
];

const inquiryRoutes: Route[] = [
    { path: '/qna/require', component: RequiredDocs },
    { path: '/qna/require/:id', component: RequiredDocsOption },
    { path: '/qna', component: FrequentlyAskedQuestions },
    { path: '/qna/new', component: NewInquiryScreen },
    { path: '/qna/detail/:id', component: InquiryDetailScreen },
    { path: '/qna/inquiry-history', component: InquiryHistoryScreen },
];

const termsRoutes: Route[] = [
    { path: '/terms/services-policy', component: TermsOfService },
    { path: '/terms/privacy-policy', component: PrivacyOfService },
    { path: '/terms/website-terms-of-use', component: WebsiteTermsOfUse },
    { path: '/terms/identifiable-terms', component: IdentifiableTerms },
    { path: '/terms/personal-collection-terms', component: PersonalCollectionTerms },
    { path: '/terms/electronic-financial-terms', component: ElecttronicFinancialTerms },
];

const historyRoutes: Route[] = [
    { path: '/history', component: HistoryScreen },
    { path: '/history/detail/:id', component: HistoryDetailScreen },
];

const noticeRoutes: Route[] = [
    { path: '/notice', component: NoticeScreen },
    { path: '/notice/:id', component: NoticeDetailScreen },
];

const claimRoutes: Route[] = [
    { path: '/claim/tourism-deduction', component: TourismDeduction },
    { path: '/claim/tourism-deduction/1', component: TourismDeduction1Step },
    { path: '/claim/tourism-deduction/2', component: TourismDeduction2Step },
    { path: '/claim/group-accident-insurance', component: GroupAccidentInsurance },
    { path: '/claim/group-accident-insurance/0', component: GroupAccidentInsurance0Step },
    { path: '/claim/group-accident-insurance/1', component: GroupAccidentInsurance1Step },
    { path: '/claim/group-accident-insurance/2', component: GroupAccidentInsurance2Step },
    { path: '/claim/group-accident-insurance/3', component: GroupAccidentInsurance3Step },
    { path: '/claim/group-accident-insurance/4', component: GroupAccidentInsurance4Step },
    { path: '/claim/group-accident-insurance/5', component: GroupAccidentInsurance5Step },
    { path: '/claim/extended-warranty-insurance', component: ExtendedWarrantyInsurance },
    { path: '/claim/extended-warranty-insurance/1', component: ExtendedWarrantyInsurance1Step },
    { path: '/claim/extended-warranty-insurance/2', component: ExtendedWarrantyInsurance2Step },
    { path: '/claim/parking-lot-liability-insurance', component: ParkingLotLiabilityInsurance },
    { path: '/claim/parking-lot-liability-insurance/1', component: ParkingLotLiabilityInsurance1Step },
    { path: '/claim/parking-lot-liability-insurance/2', component: ParkingLotLiabilityInsurance2Step },
    { path: '/claim/tugboat-operator-liability-insurance', component: TugboatOperatorLiabilityInsurance },
    { path: '/claim/tugboat-operator-liability-insurance/1', component: TugboatOperatorLiabilityInsurance1Step },
    { path: '/claim/tugboat-operator-liability-insurance/2', component: TugboatOperatorLiabilityInsurance2Step },
];

const routes: Route[] = [
    { path: '/', component: Home },
    ...verifyRoutes,
    ...accountRoutes,
    ...inquiryRoutes,
    ...termsRoutes,
    ...historyRoutes,
    ...noticeRoutes,
    ...claimRoutes,
];

export default routes;
